import Request from '@/utils/request'

export function getTypeListApi (data) {
  return Request({
    url: '/product/type',
    params: data
  })
}

export function saveTypeApi (data) {
  return Request({
    url: '/product/type/save',
    method: 'post',
    data
  })
}

export function delTypeApi (params) {
  return Request({
    url: '/product/type/del',
    method: 'post',
    data: params
  })
}
