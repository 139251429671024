<template>
  <div v-loading="loading" class="type">
    <el-button @click="handleAddType" type="primary">新增类别</el-button>
    <el-table :data="tableData" stripe style="width: 100%">
      <el-table-column prop="productTypeName" label="类别" />
      <el-table-column prop="createTime" label="创建时间" />
      <el-table-column label="操作" width="100px" >
        <template #default="scope">
          <el-button type="text" @click="handleDel(scope.row._id)" style="color:#F56C6C;">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="pagination"
      v-model:current-page="currentPage"
      :page-size="20"
      layout="total, prev, pager, next"
      :total="total"
      @current-change="handleCurrentChange"
    />
    <EditDialog v-if="dialogShow" :refresh-list="getList" @close="handleDialogClose"/>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted } from 'vue'
import EditDialog from './edit.vue'
import { ElMessage } from 'element-plus'
import {
  getTypeListApi,
  delTypeApi
} from './fetch'
export default {
  components: {
    EditDialog
  },
  setup () {
    const state = reactive({
      tableData: [],
      currentPage: 1,
      total: 0,
      loading: false,
      dialogShow: false

    })
    onMounted(async () => {
      await getList()
    })

    const handleAddType = () => {
      state.dialogShow = true
    }
    const handleDialogClose = () => {
      state.dialogShow = false
    }

    const getList = async () => {
      try {
        state.loading = true
        const res = await getTypeListApi({
          page: state.currentPage,
          rows: 20
        })
        state.tableData = res.data
        state.total = res.total
      } catch (e) {
        ElMessage({
          message: e,
          type: 'error'
        })
      } finally {
        state.loading = false
      }
    }

    const handleCurrentChange = async () => {
      try {
        await getList()
      } catch (e) {
        this.$error(e)
      }
    }

    const handleDel = async (id) => {
      try {
        await delTypeApi({
          id
        })
        ElMessage({
          type: 'success',
          message: '删除成功！'
        })
        getList()
      } catch (e) {
        ElMessage({
          type: 'error',
          message: e
        })
      }
    }

    return {
      ...toRefs(state),
      handleAddType,
      handleDialogClose,
      getList,
      handleCurrentChange,
      handleDel
    }
  }
}
</script>

<style lang="stylus" scoped>
.type
  .pagination
    text-align center

</style>
